@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

:root {
  --primary-color: #fc364e;
  --secondary-color: #081620;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background: #f8fafc;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white' %3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}


body .Toastify__toast-container {
  z-index: 500000000000 !important;
  width: auto !important;
  top: 44% !important;
}


body .Toastify__toast-container div{
  color: white !important;
  opacity: 1 !important;
}

.modal_bg {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3000000;
}

#nav {
  position: fixed;
  width: 100%;
  top: 0px;
  padding-top: 5px;
  height: 60px;
  color: white;
  z-index: 200000;
}

.user_avatar {
  height: 50px;
  width: 50px;
  background: #464545;
  border-radius: 50%;
}

.nav-colored {
  background-color: white;
  color: black !important;
  box-shadow: 0 2px 10px -1px rgb(165, 165, 165);
}

#otpBox {
  display: flex !important;
  opacity: 1 !important;
}

.nav-colored .menu_icon {
  color: black !important;
}

/* #nav .menu_icon {
  color: white;
} */

/* .nav-transparent {
  background-color: transparent;
  color: white !important;
} */

.Hero_section {
  background: rgba(0, 0, 0, 0.5) url("./assets/home.jpg");
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
  color: white;
}

.hero_h {
  font-size: 3.5rem;
  font-weight: bold;
}

.hero_main .calculator {
  padding: 20px;
}

.calculator {
  background: white !important;
  z-index: 20000;
  color: black;
  width: 38%;
}

.nav-link {
  white-space: nowrap !important;
}

.calculator .packages {
  max-height: 250px;
  overflow-y: auto;
}

.calculator .packages::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgb(165, 164, 164);
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: var(--primary-color);
  /* box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
}

.calculator .form-control {
  border-radius: 0px;
  box-shadow: none !important;
}

.pay_icon {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
}

.calculator #country {
  height: 200px;
  left: 0%;
  overflow-y: scroll;
  width: 100%;
}

.calculator #flag {
  width: 70px;
  display: none;
}

.calculator .curr {
  background: rgb(240, 240, 240);
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gainsboro;
}

p {
  margin-bottom: 0px !important;
}

.text-muted {
  font-size: 0.9rem;
  line-height: 1.25;
}

.small {
  font-size: 0.8rem;
  line-height: 1.1;
  margin-top: 5px;
}

/* How it works */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

/* FAQS */
.FAQS .accordion-item {
  box-shadow: 0px 0px 8px 1px rgba(241, 241, 241, 0.5);
}

/* FOOTER */
.footer {
  background: var(--secondary-color);
  color: white;
}

.footer .social_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  color: white;
  height: 25px;
  width: 25px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s all ease;
}

.footer-col {
  width: 301px;
  margin-top: 25px;
}

.footer .social_icon:hover {
  opacity: 0.8;
}

/* REGISTER */
.register-fields .field {
  width: 350px;
}

.Register .form-control {
  box-shadow: 0px 0px 7px 1px rgb(233, 232, 232);
  padding: 8px 15px;
}

.register_1 {
  width: 30%;
}

.register_2 {
  width: 60%;
}

.verify {
  min-height: 100vh;
}

.verify input[type="file"] {
  display: none;
}

.custom-file-upload {
  width: 100%;
}

.verify_img {
  height: 130px;
}

.verify_img2 {
  height: 200px;
}


#termsandconditions {
  font-family: "Poppins", sans-serif !important;
}

/* ABOUT */
.About {
  background: white;
}

.content-1 {
  width: 50%;
}

.content-2 {
  width: 50%;
}

/* CONTACT */
.contact .icon {
  background: var(--primary-color);
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

.special-card {
  background-size: cover !important;
  background-position: center !important;
  height: 290px;
  background-color: rgba(0, 0, 0, 0.2) !important;
  background-blend-mode: darken !important;
  width: 45%;
}

.special_btn:hover {
  background: white;
  color: black !important;
}

/* ProfileSection */
/* .ProfileSection .navbar{
  z-index: 200000000;
} */

.profile_box {
  width: 70%;
  box-shadow: 0px 0px 10px 1px rgba(241, 241, 241, 0.5);
}

.modal-dialog {
  max-width: 50%;
}

/* CHAT */
.chat_drop {
  z-index: 200000;
}

.chat_drop .dropdown_menu {
  width: 40%;
  position: fixed;
  right: 5%;
  top: calc(86% - 310px);
  height: 300px;
  z-index: 200000;
  background: white !important;
}

.avatar {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-position: center !important;
  background-size: cover !important;
  background-color: #f2efef;
}

.msgs {
  height: 80%;
  overflow-y: scroll;
  margin-top: 40px;
  padding-bottom: 40px;
}

.chat_icon {
  position: fixed;
  top: 86%;
  right: 5%;
  cursor: pointer;
  width: 55px;
  height: 55px;
  background: white;
  border-radius: 50%;
  color: var(--primary-color);
  transition: 0.3s all ease;
}

.chat_icon:hover {
  scale: 1.1;
}

.send_box {
  position: absolute;
  top: calc(100% - 100px);
  width: 90%;
  left: 5%;
}

/* ORDER HISTORY */
.Orderhistory td,
.Orderhistory th {
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}

/* UTILITIES */
/* .pending {
  background: rgb(252, 141, 141);
  color: rgb(179 0 0);
}

.process {
  background: #d9eeff;
  color: #0958d9;
}
.success {
  background: rgb(186 250 184);
  color: rgb(5, 146, 0);
}
 */

.red {
  background: rgb(252, 141, 141);
  color: rgb(179 0 0);
}

.blue {
  background: #d9eeff;
  color: #0958d9;
}

.green {
  background: rgb(186 250 184);
  color: rgb(5, 146, 0);
}

.yellow {
  background: rgb(244, 252, 173);
  color: rgb(151, 151, 1);
}

.bb {
  border: 1.99px solid gainsboro;
}

.shadow {
  box-shadow: 0px 0px 10px 1px rgba(241, 241, 241, 0.5);
}

.highlight {
  color: var(--primary-color) !important;
}

.loader {
  position: absolute;
  height: 80px;
  width: 80px;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.canvas {
  width: 90% !important;
  margin: auto !important;
}

.continue {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1.05rem;
  text-decoration: none;
  color: white !important;
  background: var(--primary-color);
  border: none;
  outline: none;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  transition: 0.3s all ease;
}

.continue:hover {
  background: #f02c43;
  color: white !important;
}

.recog .modal-dialog{
  max-width: 75% !important;
}

.bg-p {
  background: var(--primary-color) !important;
}

.bg_primary {
  background: var(--primary-color) !important;
}

.bg-gray {
  background: rgb(241, 241, 241);
  width: fit-content;
}

.local {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.bg-blue {
  background: var(--primary-color) !important;
  color: white;
  width: fit-content;
}

.alert_text {
  font-size: 1.05rem;
}

.font2 {
  font-family: "Raleway", sans-serif;
  color: #57647c;
}

.mt-10 {
  margin-top: 90px !important;
}

.title_bg {
  position: relative;
  background-color: var(--secondary-color);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 5px;
}

.title_txt {
  padding-bottom: 35px;
  padding-top: 60px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  transition: 0.5s all ease;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100%;
  height: 100vh;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.overlay {
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.upload-btn-wrapper:hover .overlay {
  display: flex;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px !important;
}

.profile_box .calculator {
  width: 90%;
  margin: auto;
}

.profile_modal {
  overflow-y: scroll;
  height: 80vh;
  pointer-events: all !important;
  width: 70%;
}

.Orderhistory .modal {
  justify-content: center;
  display: flex;
  height: 100vh;
  align-items: center;
  z-index: 500000000000;
  background: rgba(0, 0, 0, 0.3);
}

.Orderhistory .modal-body {
  overflow-y: scroll;
  max-height: 75vh;
}

#termsandconditions .modal-dialog,
.Orderhistory .modal-dialog {
  max-width: 90% !important;
  width: 90% !important;
}

.modal_bg {
  font-size: 1.05rem;
}

.modal-backdrop.show {
  z-index: 200000 !important;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(211, 211, 211, 0.5);
}

.sublist-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  height: 90vh;
  width: 50%;
  position: fixed;
  top: 3%;
  left: 25%;
  z-index: 2000000;
}

.sublist-container ul {
  list-style-type: none;
  padding: 0;
  overflow-y: scroll;
  height: 75%;
}

.sublist-container li {
  margin-bottom: 10px;
}

/* #cardNumber{
  width: 70%;
} */

.accordion-button {
  box-shadow: none !important;
}

#__react-alert__ div div div {
  width: auto !important;
}

.product_card {
  width: 350px;
}

.img_tienda img {
  height: auto;
  display: block;
  width: 667px;
  max-width: 85%;
  margin: auto;
}

@media (min-width: 851px) {
  .Hero_section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 115vh;
  }
}

@media (max-width: 1100px) {
  .hero_h {
    font-size: 2.5rem;
  }

  .img_tienda img {
    max-width: 100%;
  }
}

@media (max-width: 850px) {
  .cookie-main {
    flex-direction: column !important;
  }

  .cookie-text {
    width: 90% !important;
  }

  .calculator {
    width: 100%;
  }

  .Hero_section .hero_main {
    margin-top: 80px !important;
  }

  .Hero_section .hero_main {
    flex-direction: column;
  }

  .two-content {
    flex-direction: column;
  }

  .content-1,
  .content-2 {
    width: 100%;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }

  .Hero_section .w-50 {
    width: 100% !important;
  }

  .Hero_section .calculator {
    width: 100%;
    margin-top: 20px;
    padding: 20px 15px;
  }

  .Whyus img {
    width: 100% !important;
    margin-right: 0%;
    height: auto !important;
  }

  .Whyus .why_content {
    width: 100% !important;
    margin-top: 20px;
  }

  .col_resp,
  .canvas_col {
    flex-direction: column !important;
  }

  .register_1,
  .register_2 {
    width: 100%;
  }

  .register_1 {
    margin-bottom: 20px;
  }

  .profile_box,
  .login_form,
  .forgot_form {
    width: 80% !important;
  }
}

@media (max-width: 750px) {
  .profile-flex {
    flex-direction: column;
  }

  .profile-flex .personal_det {
    width: 90% !important;
  }

  .page-heading {
    font-size: 3rem !important;
  }

  .special-card {
    width: 100%;
  }

  .special-card:last-child {
    margin-top: 40px !important;
  }
}

@media (max-width: 650px) {
  #termsandconditions {
    max-width: 98% !important;
  }

  #termsandconditions .modal-dialog,
  .Orderhistory .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
  }

  .sublist-container {
    width: 70% !important;
  }

  .sublist-container {
    left: 15% !important;
  }

  .product_row {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .img_tienda {
    width: 100% !important;
  }

  .product_row .table {
    width: 100% !important;
  }

  .product_row .prod_details {
    margin-left: 0px !important;
    margin-top: 30px !important;
    width: 100% !important;
  }

  .product_card {
    margin-right: 0px !important;
  }

  .product_card img {
    width: 100%;
    height: auto !important;
  }

  .profile_modal {
    width: 95%;
    height: 90vh;
  }

  .chat_drop .dropdown_menu {
    width: 70%;
  }

  .banner {
    height: 35vh !important;
    background-position: bottom !important;
  }

  .profile_box,
  .login_form,
  .forgot_form {
    width: 90% !important;
    padding: 20px !important;
  }

  .profile_box .avatar {
    height: 100px !important;
    width: 100px !important;
  }

  .profile_box .prof_info {
    margin-left: 15px !important;
  }

  .prof_info h1 {
    font-size: 1.5rem;
  }

  .register-fields .field {
    width: 100%;
  }

  .nav-colored {
    color: white;
  }

  .menu {
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20vh;
    height: 100vh;
    width: 55%;
    top: 0;
    left: 0;
    z-index: 20000;
    background: var(--secondary-color);
    color: white !important;
  }

  .menu a {
    margin-left: 0px !important;
    padding: 10px 0px;
    font-size: 1.05rem;
  }

  .modal-dialog,
  .sublist-container {
    max-width: 90%;
  }

  .sublist-container {
    left: 5% !important;
  }

  .profile_box .prof_info {
    width: calc(100% - 100px);
  }

  .product_card {
    width: 100%;
    text-align: center;
  }
  .recog .modal-dialog{
    max-width: 100% !important;
    margin: 0 !important;
  }
}

.profile_modal_bg {
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  z-index: 200000000000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 550px) {
  .calculator {
    padding: 10px !important;
  }

  .alert_text {
    font-size: 1rem;
  }

  .Orderhistory .modal-dialog,
  .sublist-container {
    width: 90% !important;
  }

  .sublist-container {
    left: 5% !important;
  }

  .avatar {
    width: 80px;
    height: 80px;
  }

  .register-fields .dropdown {
    width: 100%;
  }

  .page-heading {
    font-size: 2.5rem !important;
  }

  #otpBox .modal-dialog,
  .sublist-container {
    width: 90% !important;
  }

  .sublist-container {
    left: 5% !important;
  }

  .OrderSection .profile_box {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .OrderSection .calculator {
    padding: 7px 0px !important;
    width: 97%;
  }
}

@media (max-width: 450px) {
  .product_card {
    width: 330px !important;
  }

  .calculator .w-50 {
    width: 100% !important;
  }

  .canvas {
    width: 92% !important;
  }

  .profile_box .personal_det .fs-4 {
    font-size: 1.2rem !important;
  }

  .profile_box .personal_det .fs-5 {
    font-size: 1.1rem !important;
  }

  .login_form,
  .forgot_form {
    width: 92% !important;
    padding: 20px 15px !important;
  }

  .OrderSection .profile_box {
    width: 94% !important;
    padding: 20px 0px !important;
  }

  .Orderhistory .modal-dialog,
  .sublist-container {
    width: 95% !important;
  }

  .sublist-container {
    left: 2.5% !important;
  }

  .page-heading {
    font-size: 2.1rem !important;
  }

  .chat_drop .dropdown_menu {
    width: 90%;
  }

  .how_works .fs-4 {
    font-size: 1.25rem !important;
  }

  .profile_box .avatar {
    height: 65px !important;
    width: 65px !important;
  }

  .profile_box .prof_info {
    width: calc(100% - 80px);
  }

  .logo {
    width: 175px;
  }

  .FAQS .accordion-item button {
    font-size: 1.05rem !important;
  }

  .hero_h {
    font-size: 2.3rem;
  }

  .menu {
    width: 70%;
  }

  .mt-10 {
    margin-top: 65px !important;
  }

  .user_avatar {
    height: 45px;
    width: 45px;
  }

  .menu_icon {
    right: 60px !important;
  }

  .NavLogo {
    height: 30px;
  }
}