.custom-tooltip-container {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  display: inline-block;
}

.tooltipp {
  position: absolute;
  top: -30px;
  left: 0;
  background-color: black;
  color: white;
  padding: 5px 10px;
  z-index: 20000000000;
  border-radius: 5px;
  font-size: 0.7rem;
  white-space: nowrap;
}
